import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<AkIconButton\n  @variant='outlined'\n  @size='small'\n  {{on 'click' (fn @setVulnerabilityDataModel @vulnerabilityPreference)}}\n  data-test-prjSettings-analysisSettings-vulnPreferenceItem-action\n>\n  {{#if @vulnerabilityPreference.riskOverridden}}\n    <AnalysisRisk::OverrideDetailsIcon\n      data-test-prjSettings-analysisSettings-vulnPreferenceItem-actionOverridenIcon\n    />\n  {{else}}\n    <AkIcon\n      @iconName='edit'\n      data-test-prjSettings-analysisSettings-vulnPreferenceItem-actionEditIcon\n    />\n  {{/if}}\n</AkIconButton>", {"contents":"<AkIconButton\n  @variant='outlined'\n  @size='small'\n  {{on 'click' (fn @setVulnerabilityDataModel @vulnerabilityPreference)}}\n  data-test-prjSettings-analysisSettings-vulnPreferenceItem-action\n>\n  {{#if @vulnerabilityPreference.riskOverridden}}\n    <AnalysisRisk::OverrideDetailsIcon\n      data-test-prjSettings-analysisSettings-vulnPreferenceItem-actionOverridenIcon\n    />\n  {{else}}\n    <AkIcon\n      @iconName='edit'\n      data-test-prjSettings-analysisSettings-vulnPreferenceItem-actionEditIcon\n    />\n  {{/if}}\n</AkIconButton>","moduleName":"irene/components/project-settings/analysis-settings/vulnerability-list/action/index.hbs","parseOptions":{"srcName":"irene/components/project-settings/analysis-settings/vulnerability-list/action/index.hbs"}});
import Component from '@glimmer/component';
import type VulnerabilityPreferenceModel from 'irene/models/vulnerability-preference';

export interface ProjectSettingsAnalysisSettingsVulnerabilityListActionSignature {
  Args: {
    vulnerabilityPreference: VulnerabilityPreferenceModel;
    setVulnerabilityDataModel: (vulPref: VulnerabilityPreferenceModel) => void;
  };
}

export default class ProjectSettingsAnalysisSettingsVulnerabilityListActionComponent extends Component<ProjectSettingsAnalysisSettingsVulnerabilityListActionSignature> {}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'project-settings/analysis-settings/vulnerability-list/action': typeof ProjectSettingsAnalysisSettingsVulnerabilityListActionComponent;
  }
}
